var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-border" },
    [
      _c(
        "div",
        { staticClass: "mainCont" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.totalStars,
              currentIndex: _vm.answersfound,
            },
          }),
          _c(
            "div",
            {
              staticClass: "background",
              class: { backgroundBlue: _vm.background == "blue" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "game-content",
                  class: { "number-bg-color": !_vm.isNumberGame },
                },
                [
                  _c("div", { staticClass: "game-left-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.leftList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "audio-area",
                                  on: {
                                    click: function ($event) {
                                      return _vm.playSoundWord(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("AudioClickPlayer", {
                                    ref: "audioBox" + item.id,
                                    refInFor: true,
                                    attrs: { audioUrl: item.sound },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "word-area",
                                  on: {
                                    click: function ($event) {
                                      return _vm.foundAnswer(item.id)
                                    },
                                  },
                                },
                                [
                                  item.pinyin
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-pinyin-medium pinyin",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.pinyin) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "font-hanzi-medium" },
                                    [_vm._v(_vm._s(item.element))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                  _vm._l(_vm.questionInfoList, function (item, index) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.question === index,
                            expression: "question === index",
                          },
                        ],
                        key: index + "question",
                        staticClass: "game-center-box",
                      },
                      [
                        _c("img", {
                          staticClass: "objectDisplay fadeIn",
                          attrs: { src: item.bgImg },
                        }),
                        _vm.isNumberGame
                          ? _c("div", { staticClass: "number-area" }, [
                              _c(
                                "span",
                                { staticClass: "font-pinyin-large pinyin" },
                                [_vm._v(_vm._s(item.pinyin))]
                              ),
                              _c(
                                "span",
                                { staticClass: "font-hanzi-large hanzi" },
                                [_vm._v(_vm._s(item.hanzi))]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  _c("div", { staticClass: "game-right-box" }, [
                    _c(
                      "div",
                      { staticClass: "game-aside-content" },
                      _vm._l(
                        _vm.asideElementInfo.rightList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item-content",
                              class: {
                                "item-content-number": _vm.isNumberGame,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "audio-area",
                                  on: {
                                    click: function ($event) {
                                      return _vm.playSoundWord(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("AudioClickPlayer", {
                                    ref: "audioBox" + item.id,
                                    refInFor: true,
                                    attrs: { audioUrl: item.sound },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "word-area",
                                  on: {
                                    click: function ($event) {
                                      return _vm.foundAnswer(item.id)
                                    },
                                  },
                                },
                                [
                                  item.pinyin
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-pinyin-medium pinyin",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.pinyin) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "font-hanzi-medium" },
                                    [_vm._v(_vm._s(item.element))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }