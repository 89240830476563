
<template>
  <div class="audio-player-container">
    <img :src="audioImg" />
  </div>
</template>

<script>
export default {
  props: {
    audioUrl: {
      type: String,
      require: true,
    },
    audioId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      audioImg: require("@/assets/img/06-Sound/sound-white.svg"),
    };
  },
  methods: {
    play() {
      const audio = new Audio(this.audioUrl);
      setTimeout(() => {
        audio.play();
      }, 150);
      this.audioImg = require("@/assets/img/06-Sound/sound-white-big.gif");
      setTimeout(() => {
        this.audioImg = require("@/assets/img/06-Sound/sound-white.svg");
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player-container {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
</style>